import { default as indexEVO0obIu0nMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/index.vue?macro=true";
import { default as login4BhIFMIjXsMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/login.vue?macro=true";
import { default as arrivatiW6g7W5M5j3Meta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/merci/arrivati.vue?macro=true";
import { default as arrivoOkEKMhLnriMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/merci/arrivo.vue?macro=true";
import { default as incompletiULona4si1YMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/merci/incompleti.vue?macro=true";
import { default as pronte_consegna9NCTvMffKAMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/merci/pronte_consegna.vue?macro=true";
import { default as _91id_93hMmBebC1asMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/[id].vue?macro=true";
import { default as createnrKfmyA4XKMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/create.vue?macro=true";
import { default as indexYWpwYBEDcaMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/index.vue?macro=true";
import { default as ingressiBNIx1G8GtPMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/ingressi.vue?macro=true";
import { default as _91id_939BhO23tSEzMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/update/[id].vue?macro=true";
import { default as uscitehdVJW7WINUMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/uscite.vue?macro=true";
import { default as _91id_93iEyXHxGV3EMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_acquisto/[id].vue?macro=true";
import { default as da_prendere_in_caricofoKVJ6bowxMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_acquisto/da_prendere_in_carico.vue?macro=true";
import { default as indexU1uyLBRXFaMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_acquisto/index.vue?macro=true";
import { default as presi_in_carico5BLCLxOl0VMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_acquisto/presi_in_carico.vue?macro=true";
import { default as incompleti1ZxKOc0IPGMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_vendita/incompleti.vue?macro=true";
import { default as indexWeapeYJNqMMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_vendita/index.vue?macro=true";
import { default as pronte_consegnaCJHDOdQckwMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_vendita/pronte_consegna.vue?macro=true";
import { default as _91id_93ufoVE0uZ9TMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/ticket_assistenza/[id].vue?macro=true";
import { default as indextWoluhSnr7Meta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/ticket_assistenza/index.vue?macro=true";
import { default as _91id_93Q5Q4Nuo2G7Meta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/uscite_consegna/[id].vue?macro=true";
import { default as consegne_in_corsoQTcwR2kXJuMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/uscite_consegna/consegne_in_corso.vue?macro=true";
import { default as consegne_programmatat0j8Wtj8BtMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/uscite_consegna/consegne_programmata.vue?macro=true";
import { default as indexC3DL0W4lsDMeta } from "/home/johan/VUE-PROJECTS/portaleFornitori/pages/uscite_consegna/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login4BhIFMIjXsMeta || {},
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "merci-arrivati",
    path: "/merci/arrivati",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/merci/arrivati.vue").then(m => m.default || m)
  },
  {
    name: "merci-arrivo",
    path: "/merci/arrivo",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/merci/arrivo.vue").then(m => m.default || m)
  },
  {
    name: "merci-incompleti",
    path: "/merci/incompleti",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/merci/incompleti.vue").then(m => m.default || m)
  },
  {
    name: "merci-pronte_consegna",
    path: "/merci/pronte_consegna",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/merci/pronte_consegna.vue").then(m => m.default || m)
  },
  {
    name: "movimenti-id",
    path: "/movimenti/:id()",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/[id].vue").then(m => m.default || m)
  },
  {
    name: "movimenti-create",
    path: "/movimenti/create",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/create.vue").then(m => m.default || m)
  },
  {
    name: "movimenti",
    path: "/movimenti",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/index.vue").then(m => m.default || m)
  },
  {
    name: "movimenti-ingressi",
    path: "/movimenti/ingressi",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/ingressi.vue").then(m => m.default || m)
  },
  {
    name: "movimenti-update-id",
    path: "/movimenti/update/:id()",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/update/[id].vue").then(m => m.default || m)
  },
  {
    name: "movimenti-uscite",
    path: "/movimenti/uscite",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/movimenti/uscite.vue").then(m => m.default || m)
  },
  {
    name: "ordini_acquisto-id",
    path: "/ordini_acquisto/:id()",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_acquisto/[id].vue").then(m => m.default || m)
  },
  {
    name: "ordini_acquisto-da_prendere_in_carico",
    path: "/ordini_acquisto/da_prendere_in_carico",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_acquisto/da_prendere_in_carico.vue").then(m => m.default || m)
  },
  {
    name: "ordini_acquisto",
    path: "/ordini_acquisto",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_acquisto/index.vue").then(m => m.default || m)
  },
  {
    name: "ordini_acquisto-presi_in_carico",
    path: "/ordini_acquisto/presi_in_carico",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_acquisto/presi_in_carico.vue").then(m => m.default || m)
  },
  {
    name: "ordini_vendita-incompleti",
    path: "/ordini_vendita/incompleti",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_vendita/incompleti.vue").then(m => m.default || m)
  },
  {
    name: "ordini_vendita",
    path: "/ordini_vendita",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_vendita/index.vue").then(m => m.default || m)
  },
  {
    name: "ordini_vendita-pronte_consegna",
    path: "/ordini_vendita/pronte_consegna",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/ordini_vendita/pronte_consegna.vue").then(m => m.default || m)
  },
  {
    name: "ticket_assistenza-id",
    path: "/ticket_assistenza/:id()",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/ticket_assistenza/[id].vue").then(m => m.default || m)
  },
  {
    name: "ticket_assistenza",
    path: "/ticket_assistenza",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/ticket_assistenza/index.vue").then(m => m.default || m)
  },
  {
    name: "uscite_consegna-id",
    path: "/uscite_consegna/:id()",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/uscite_consegna/[id].vue").then(m => m.default || m)
  },
  {
    name: "uscite_consegna-consegne_in_corso",
    path: "/uscite_consegna/consegne_in_corso",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/uscite_consegna/consegne_in_corso.vue").then(m => m.default || m)
  },
  {
    name: "uscite_consegna-consegne_programmata",
    path: "/uscite_consegna/consegne_programmata",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/uscite_consegna/consegne_programmata.vue").then(m => m.default || m)
  },
  {
    name: "uscite_consegna",
    path: "/uscite_consegna",
    component: () => import("/home/johan/VUE-PROJECTS/portaleFornitori/pages/uscite_consegna/index.vue").then(m => m.default || m)
  }
]