/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Composables
import { createVuetify } from "vuetify";
import { defineNuxtPlugin } from "#app/nuxt";
import { md3 } from "vuetify/blueprints";

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    blueprint: md3,
    theme: {
      themes: {
        light: {
          colors: {
            primary: "#1867C0",
            secondary: "#5CBBF6"
          }
        }
      }
    },
    components: {}
    // date: {
    //   adapter: DateFnsAdapter,
    //   locale: {
    //     en: enUS,
    //     it
    //   }
    // }
  });
  app.vueApp.use(vuetify);
});
