import { defineStore } from "pinia";
import { $fetch } from "ofetch";
import { useGetData } from "~/composables/useGetData";

const { data, getData, loading } = useGetData();
export const useColliStore = defineStore("colliStore", {
  state: () => ({
    colli: data,
    caricandoColli: loading
  }),
  actions: {
    async getColliOdoo() {
      await getData("/api/colli");
    },
    bulkUpdateColli(updateData) {
      // Create a fast lookup using Uint32Array for better performance
      const maxId = Math.max(...this.colli.map((c) => c.id));
      const lookupArray = new Uint32Array(maxId + 1);
      lookupArray.fill(0xffffffff); // Fill with sentinel value

      // Build lookup table - O(n) operation
      for (let i = 0; i < this.colli.length; i++) {
        lookupArray[this.colli[i].id] = i;
      }

      // Prepare all updates
      const updates = new Map();
      for (const newCollo of updateData.colli) {
        const index = lookupArray[newCollo.id];
        if (index !== 0xffffffff) {
          updates.set(index, newCollo);
        }
      }

      // Apply all updates in a single batch
      this.$patch((state) => {
        for (const [index, newData] of updates) {
          state.colli[index] = { ...state.colli[index], ...newData };
        }
      });
    }
  }
});
