import { defineStore } from "pinia";
import { tryCatch } from "standard-as-callback/built/utils";
import { useColliStore } from "./colliStored";
import { useGetData } from "~/composables/useGetData";
import { usePostData } from "~/composables/usePostData";
import type { Movimento } from "~/types";
import { usePostDataFileStream } from "~/composables/usePostDataFileStream";

const { data, getData, loading, messageError } = useGetData();
export const useMovimentiStore = defineStore("movimentiStore", {
  state: () => ({
    movimenti: data,
    movimentoSelezionato: {},
    caricandoMovimenti: loading,
    caricandoDatiDDT: false,
    errorMessage: messageError
  }),
  getters: {
    isMovimentoSelezionato(state) {
      if (
        !state.movimentoSelezionato ||
        Object.keys(state.movimentoSelezionato).length === 0
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  actions: {
    async getMovimentiOdoo() {
      await getData("/api/movimenti");
    },
    async searchMovimentiFiltrati(filtri: Array<Object>) {
      this.caricandoMovimenti = true;
      const { data, postData, loading, messageError } = usePostData();
      await postData("/api/movimenti/cercamovimenti", filtri);
      if (!messageError.value) {
        this.movimenti = data.value.result;
        this.caricandoMovimenti = loading.value;
      } else {
        this.errorMessage = messageError;
      }
    },
    async createMovimento(movimento) {
      const { data, postData, loading, messageError } = usePostData();
      await postData("/api/movimenti", movimento);
      if (!messageError.value) {
        // Add to a specified location
        // this.movimenti.push(data.value.movimento);
        this.movimenti.splice(0, 0, data.value.movimento);
        this.caricandoMovimenti = loading.value;
        // this.movimentoSelezionato = data.value.movimento;
      } else {
        this.errorMessage = messageError;
      }
      return data.value.movimento;
    },
    async updateMovimento(movimento) {
      const { data, postData, loading, messageError } = usePostData();
      await postData("/api/movimenti/update", movimento);
      if (!messageError.value) {
        this.aggiornareElemento(data.value.movimento);
        this.aggiornaColli(data.value.movimento);
        this.caricandoMovimenti = loading.value;
      } else {
        this.errorMessage = messageError;
      }
      this.caricandoMovimenti = false;
    },
    async deleteMovimentoOdoo(movimento) {
      const { data, postData, loading, messageError } = usePostData();
      await postData("/api/movimenti/delete", movimento);
      if (!messageError.value) {
        const item = this.movimenti.filter((item) => {
          return item.id === parseInt(movimento.id);
        })[0];
        const index = this.movimenti.indexOf(item);
        this.movimenti.splice(index, 1);
        this.caricandoMovimenti = loading.value;
      } else {
        this.errorMessage = messageError;
      }
    },
    aggiornareElemento(data) {
      const index = this.movimenti.findIndex(({ id }) => id === data.id);
      Object.assign(this.movimenti[index], data);
      this.movimentoSelezionato = data;
      console.log(this.movimentoSelezionato);
    },
    aggiornaColli(data) {
      const colliStore = useColliStore();
      colliStore.bulkUpdateColli(data);
    },
    async confermaMovimento(movimento) {
      const { data, postData, loading, messageError } = usePostData();
      await postData("/api/movimenti/conferma_movimento", movimento);
      if (!messageError.value) {
        console.log("colli ", data.value.movimento);
        this.aggiornareElemento(data.value.movimento);
        this.aggiornaColli(data.value.movimento);
        this.caricandoMovimenti = loading.value;
      } else {
        this.errorMessage = messageError;
      }
    },
    resetMovimentoSelezionato() {
      this.movimentoSelezionato = [];
    },
    async creaDDTOdoo(body) {
      const { data, postData } = usePostData();
      await postData("/api/movimenti/crea_ddt", body);
      const ddtId = data.value.ddt_id;
      const movimentiIds = data.value.movimenti_ids;
      const movimentiDaAggiornare = this.movimenti.filter((item) => {
        return movimentiIds.includes(item.id);
      })[0];

      if (movimentiIds.length > 1) {
        for (const movimento of movimentiDaAggiornare) {
          movimento.ddt_id = ddtId;
          movimento.ddt_name = data.value.ddt_name;
          movimento.data_spedizione = data.value.data_spedizione;
        }
      } else {
        movimentiDaAggiornare.ddt_id = ddtId;
        movimentiDaAggiornare.ddt_name = data.value.ddt_name;
        movimentiDaAggiornare.data_spedizione = data.value.data_spedizione;

        this.movimentoSelezionato.ddt_id = ddtId;
        this.movimentoSelezionato.ddt_name = data.value.ddt_name;
      }
    },
    async stampaDDTOdoo(body) {
      const { data, postDataStream } = usePostDataFileStream();
      await postDataStream("/api/movimenti/stampa_ddt", body);
      return data.value;
    },
    async uploadFileOdoo(body) {
      const { data, postData, messageError } = usePostData();
      await postData("/api/movimenti/upload_file", body);
      if (!messageError.value) {
        this.aggiornareElemento(data.value.movimento_id);
      } else {
        this.errorMessage = messageError;
      }
    },
    async getDatiDDTOdoo(body) {
      this.caricandoDatiDDT = true;
      const { data, postData } = usePostData();
      await postData("/api/movimenti/get_dati_ddt", body);
      this.caricandoDatiDDT = false;
      return data.value.dati_ddt;
    }
  }
});
